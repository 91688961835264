var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-right",attrs:{"xs12":"","sm4":"","md4":"","lg":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":_vm.$t('search owner')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.filterOwners},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":item.avatar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"width":"3","color":_vm.$store.state.primaryColor,"indeterminate":""}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.names",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.getFullnames(item.names)))])]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.DateFormat(item.dateCreated)))])]}},{key:"item.accessRight",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"loading":_vm.snackloading,"color":_vm.$store.state.secondaryColor},on:{"change":function($event){return _vm.accessRight(item)}},model:{value:(item.accessRight),callback:function ($$v) {_vm.$set(item, "accessRight", $$v)},expression:"item.accessRight"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-icon',{attrs:{"color":"success darken-3"}},[_vm._v("fas fa-check-circle")]):_c('v-icon',{attrs:{"color":"error darken-3"}},[_vm._v("fas fa-times-circle")])]}},{key:"item.action",fn:function(){return _vm._l((_vm.icons),function(button){return _c('v-icon',{key:button.icon,staticClass:"mx-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":button.color},on:{"click":function($event){return _vm.goTo(button.route)}}},[_vm._v(_vm._s(button.icon))])})},proxy:true}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"bottom":"","color":"success lighten-5 success--text text--darken-3"},model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("access right updated")))]),_c('v-btn',{attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }