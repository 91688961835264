<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm4 md4 lg class="text-right">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search owner')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="filterOwners"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar size="35">
              <v-img :src="item.avatar">
                <template v-slot:placeholder>
                  <v-row justify="center" align="center">
                    <v-progress-circular
                      width="3"
                      :color="$store.state.primaryColor"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.names`]="{ item }">
            <span class="caption">{{ getFullnames(item.names) }}</span>
          </template>

          <template v-slot:[`item.dateCreated`]="{ item }">
            <span class="caption">{{ DateFormat(item.dateCreated) }}</span>
          </template>

          <template v-slot:[`item.accessRight`]="{ item }">
            <v-switch
              v-model="item.accessRight"
              :loading="snackloading"
              :color="$store.state.secondaryColor"
              @change="accessRight(item)"
            ></v-switch>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status" color="success darken-3"
              >fas fa-check-circle</v-icon
            >
            <v-icon v-else color="error darken-3">fas fa-times-circle</v-icon>
          </template>

          <template v-slot:[`item.action`]>
            <v-icon
              v-for="button in icons"
              :key="button.icon"
              small
              :color="button.color"
              class="mx-1"
              style="cursor: pointer"
              @click="goTo(button.route)"
              >{{ button.icon }}</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      color="success lighten-5 success--text text--darken-3"
    >
      <span>{{ $t("access right updated") }}</span>

      <v-btn icon color="success darken-3" @click="snackSuccess = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import format from "date-fns/format";
export default {
  data: () => ({
    owners: [],
    loading: true,
    snackloading: false,
    search: "",
    icons: [
      { icon: "visibility", route: "", color: "#038cbe" },
      { icon: "edit", route: "", color: "#ef6c00" },
      { icon: "delete", route: "", color: "red" },
    ],

    snackSuccess: false,
  }),

  computed: {
    filterOwners() {
      return this.owners;
    },
    headers() {
      return [
        {
          text: "#",
          value: "avatar",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("names"),
          value: "names",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("email"),
          value: "email",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("access right"),
          value: "accessRight",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("is logged in"),
          value: "status",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("date created"),
          value: "dateCreated",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      this.owners = [];
      const snapshot = await db.collection("rootAccess").get();

      snapshot.forEach((snaps) => {
        db.collection("users")
          .where("username", "==", snaps.data().email)
          .get()
          .then((response) => {
            response.forEach((element) => {
              this.owners.push({
                id: snaps.id,
                userId: element.id,
                accessRight: element.data().accessRight,
                status: element.data().status,
                ...snaps.data(),
              });
            });
          });
        this.loading = false;
      });
    },

    DateFormat(data) {
      return format(data.toDate(), "EEE, dd MMMM, yyyy hh:mm");
    },

    getFullnames(data) {
      return data.first + " " + data.middle.substr(0, 1) + ". " + data.last;
    },

    accessRight(data) {
      this.snackloading = true;
      db.collection("users")
        .doc(data.userId)
        .update({
          accessRight: data.accessRight,
        })
        .then(() => {
          this.snackSuccess = true;
          this.snackloading = false;
        });
    },
  },
};
</script>

<style></style>
